import Parser, {
  domToReact,
  DOMNode
} from "html-react-parser";
import * as React from "react";
import {
  Text,
  Element
} from "domhandler";

import Icon from "../icon";
import Link from "../link";

interface IProps {
  className?: string;
  type: "plain" | "formatted";
  content: string;
}

const isTextNode = (domNode: DOMNode): domNode is Text => domNode.nodeType === 2;
const isElementNode = (domNode: DOMNode): domNode is Element => domNode.nodeType === 1;

const Wysiwyg = ({
  className, type, content
}: IProps) => {
  return (
    <>
      {Parser(content, {
        replace: domNode => {
          const newlineRegex = /(?:\r\n)|(?:\r)|(?:\n)/gi;

          // Create a JSX.Element for plain text
          if (
            type === "plain" &&
            isTextNode(domNode) &&
            domNode.data &&
            domNode.type === "text" &&
            newlineRegex.test(domNode.data)
          ) {
            domNode.data = domNode.data.replace(
              /(?:\r\n)|(?:\r)|(?:\n)/gi,
              "<br />"
            );

            return (
              <span
                className={className}
                dangerouslySetInnerHTML={{ __html: domNode.data }}
              />
            );
          }

          // Replace <a> tags with our own <Link> JSX.Element
          if (
            isElementNode(domNode) &&
            domNode.children &&
            domNode.name === "a"
          ) {
            // Return the domNode if there is no data inside the child
            if (!(domNode.children[ 0 ] as Text).data) {
              return domNode;
            }

            return (
              <Link
                type="inline"
                href={domNode.attribs ? domNode.attribs.href : "#"}
              >
                {(domNode.children[ 0 ] as Text).data}
              </Link>
            );
          }

          if (isElementNode(domNode) && domNode.name === "blockquote") {
            return (
              <blockquote>
                <span className="speech-mark">
                  <Icon
                    className="icon"
                    icon="speechMarks"
                    size={2}
                  />
                </span>

                {domToReact(domNode.children)}
              </blockquote>
            );
          }

          return domNode;
        }
      })}
    </>
  );
};

export default Wysiwyg;
