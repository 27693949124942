import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import AuthorSocialLinks from "../components/author-social-links/author-social-links";
import { HalfColumnWidthWrapper } from "../components/confines-wrappers";
import DynamicImage from "../components/dynamic-image";
import FieldTechnicalArticle from "../components/field-technical-article";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import Layout from "../components/layout";
import RelatedInsights from "../components/related-insights";
// import RelatedLatestArticles from '../components/related-latest-articles';
import TeamMembers from "../components/team-members";
import Wysiwyg from "../components/wysiwyg";
import {
  IProfileData,
  IProfileFields
} from "../pages/about-us";
import { IInsightsArticleData } from "../pages/insights";
import { IBlogArticleData } from "../pages/latest";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../theme/theme-helper";

interface IProfileProps {
  profileTeamProfile: IProfileFields;
  nodeAboutUsOverview: {
    relationships: {
      field_ordered_team_members: IProfileData[];
    };
  };
  allNodeBlogArticle: {
    edges: IBlogEdges[];
  };
  allNodeLabsArticle: {
    edges: ILabEdges[];
  };
}

interface IBlogEdges {
  node: IBlogArticleData;
}
interface ILabEdges {
  node: IInsightsArticleData;
}

interface ITeamMembersWrapper {
  invertContrast: boolean;
}

const ProfileWrapper = styled.div`
  .profile-bio-wrapper {
    transition: 0.2s ease-in-out;
    padding-top: 6.4rem;

    .profile-bio {
      .heading {
        transition: 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: 16.8rem;

        h1 {
          display: flex;
          flex-direction: column;
          position: relative;
          margin: 0;
          width: 100%;
          text-align: center;

          span {
            display: block;
          }

          .name {
            order: 2;
            color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};
          }

          .role {
            order: 1;
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
            letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
            text-transform: uppercase;
          }
        }

        .image-wrapper {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;

          .image {
            margin: 0 auto;
            width: 15rem;
          }
        }

        .socials {
          a {
            .icon {
              fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
            }

            &:hover {
              .icon {
                fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColorLight"
    })};
              }
            }
          }
        }

        .hr {
          transition: 0.2s ease-in-out;
          display: block;
          padding-top: 2.4rem;

          span {
            display: block;
            margin: 0 auto;
            width: 5rem;
            height: 0.5rem;
            background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          }
        }
      }

      .content {
        transition: 0.2s ease-in-out;
        padding-top: 3.8rem;

        .summary {
          p {
            margin: 0;
            padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};

            strong {
              font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
            }

            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .profile-bio-wrapper {
      .profile-bio {
        .heading {
          h1 {
            .name {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
            }

            .role {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
            }
          }
        }

        .content {
          padding-top: 4.2rem;
        }
      }
    }
  }

  @media (min-width: 600px) {
    .profile-bio-wrapper {
      .profile-bio {
        .heading {
          .hr {
            padding-top: 3.2rem;
          }
        }

        .content {
          padding-top: 6.8rem;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .profile-bio-wrapper {
      padding-top: 8.2rem;

      .profile-bio {
        .content {
          padding-top: 8.2rem;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .profile-bio-wrapper {
      .profile-bio {
        .heading {
          h1 {
            .name {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
            }

            .role {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
            }
          }
        }

        .content {
          .summary {
            p {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .profile-bio-wrapper {
      .profile-bio {
        .heading {
          h1 {
            .name {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
            }

            .role {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
            }
          }
        }
      }
    }
  }
`;

const TeamMembersWrapper = styled<ITeamMembersWrapper, "div">("div")`
  background-color: ${props =>
    props.invertContrast ?
      ({ theme }) => colourHelper({
        theme,
        variant: "altLightGrey"
      }) :
      ({ theme }) => colourHelper({
        theme,
        variant: "white"
      })};
`;

const Profile = ({ data }: { data: IProfileProps }) => {
  const profile = data.profileTeamProfile;

  const teamMembers =
    data.nodeAboutUsOverview.relationships.field_ordered_team_members;

  const pr = profile.relationships;
  const mugshot = pr && pr.field_mugshot;
  const fieldTeamAbout = pr && pr.field_team_about;

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "About us",
      path: "/about-us"
    },
    {
      name: profile.field_full_name,
      path: profile.path.alias
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout>
      <>
        <HelmetWrapper
          title={`${profile.field_full_name} (${profile.field_job_title})`}
          description={profile.field_team_micro_snippet}
          updatedAt={profile.changed}
          canonicalPath={profile.path.alias}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="light"
          />
        </header>

        <main
          id="main-content"
          role="main"
        >
          <ProfileWrapper>
            <div className="profile-bio-wrapper">
              <div className="content-container profile-bio">
                <HalfColumnWidthWrapper>
                  <div className="heading">
                    <h1>
                      <span className="name">
                        {profile.field_full_name}
                      </span>

                      <span className="role">
                        <span className="off-screen">
                          {" "}
                          (
                        </span>

                        {profile.field_job_title}

                        <span className="off-screen">
                          )
                        </span>
                      </span>
                    </h1>

                    {mugshot && (
                      <div className="image-wrapper">
                        <DynamicImage
                          className="image"
                          data={mugshot}
                          alt={profile.field_full_name}
                        />
                      </div>
                    )}

                    {profile.field_instagram_username ||
                    profile.field_twitter_username ? (
                      <AuthorSocialLinks
                        twitterUsername={profile.field_twitter_username}
                        instagramUsername={profile.field_instagram_username}
                      />
                      ) : (
                        <span className="hr">
                          <span />
                        </span>
                      )}
                  </div>
                </HalfColumnWidthWrapper>

                <div className="content">
                  <div className="summary">
                    <HalfColumnWidthWrapper>
                      {profile.field_team_summary && (
                        <Wysiwyg
                          type="formatted"
                          content={profile.field_team_summary.processed}
                        />
                      )}
                    </HalfColumnWidthWrapper>
                  </div>

                  {fieldTeamAbout && (
                    <FieldTechnicalArticle
                      type="basic"
                      paragraphs={fieldTeamAbout}
                    />
                  )}
                </div>
              </div>
            </div>

            <aside>
              {data.allNodeLabsArticle &&
                data.allNodeLabsArticle.edges.length > 0 && (
                  <RelatedInsights
                    invertContrast
                    articles={data.allNodeLabsArticle.edges}
                    authorName={profile.field_full_name}
                  />
              )}

              {/* {data.allNodeBlogArticle &&
                data.allNodeBlogArticle.edges.length > 0 && (
                  <RelatedLatestArticles
                    invertContrast={data.allNodeLabsArticle.edges.length === 0}
                    articles={data.allNodeBlogArticle.edges}
                    authorName={profile.field_full_name}
                  />
                )} */}

              <TeamMembersWrapper
                // invertContrast={
                //   (data.allNodeLabsArticle.edges.length > 0 &&
                //     data.allNodeBlogArticle.edges.length > 0) ||
                //   (data.allNodeLabsArticle.edges.length === 0 &&
                //     data.allNodeBlogArticle.edges.length === 0)
                // }
                invertContrast={data.allNodeLabsArticle.edges.length === 0}
              >
                <TeamMembers teamMembers={teamMembers} />
              </TeamMembersWrapper>
            </aside>
          </ProfileWrapper>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default Profile;

export const query = graphql`
  query($slug: String!, $id: String!) {
    profileTeamProfile(path: { alias: { eq: $slug } }) {
      ...getProfileData
    }
    nodeAboutUsOverview {
      relationships {
        field_ordered_team_members {
          relationships {
            profile__team_profile {
              ...getProfileData
            }
          }
        }
      }
    }
    allNodeBlogArticle(
      limit: 2
      filter: { relationships: { uid: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          ...getLatestData
        }
      }
    }
    allNodeLabsArticle(
      limit: 2
      filter: { relationships: { uid: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          ...getLabsData
        }
      }
    }
  }
`;
