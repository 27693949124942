import React from "react";

import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import DynamicHtmlTag from "../dynamic-html-tag";
import DynamicImage from "../dynamic-image";
import Link from "../link";

interface IUserBadge {
  headingTag?: "h2" | "h3" | "h4" | "h5" | "h6";
  fullName: string;
  summary?: string;
  job: string;
  path: string;
  cover?: ILocalFile<IFluid>;
}

const UserBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
  padding-top: 15.6rem;
  width: 18.6rem;

  .heading {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0;
    width: 100%;
    text-align: center;

    span {
      display: block;
    }

    .name {
      order: 2;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};

      a {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};

        &:hover {
          color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
        }
      }
    }

    .role {
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "midGrey"
  })};
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_1"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
      letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
      text-transform: uppercase;
    }
  }

  .image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;

    .image {
      margin: 0 auto;
      width: 15rem;
    }
  }

  .summary {
    padding-top: 1rem;
    color: ${({ theme }) => colourHelper({
    theme,
    variant: "midGrey"
  })};
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "small_3"
  })};
    font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
  }
`;

const UserBadge = ({
  headingTag,
  fullName,
  job,
  summary,
  cover,
  path
}: IUserBadge) => {
  return (
    <UserBadgeWrapper>
      <header role="banner">
        <DynamicHtmlTag
          className="heading"
          htmlTag={headingTag || "h3"}
        >
          <>
            <span className="name">
              <Link
                type="none"
                href={path}
              >
                {fullName}
              </Link>
            </span>

            <span className="role">
              <span className="off-screen">
                {" "}
                (
              </span>

              {job}

              <span className="off-screen">
                )
              </span>
            </span>
          </>
        </DynamicHtmlTag>
      </header>

      {cover && (
        <Link
          className="image-wrapper"
          type="none"
          href={path}
        >
          <DynamicImage
            className="image"
            data={cover}
            alt={fullName}
          />
        </Link>
      )}

      {summary && (
        <p className="summary">
          {summary}
        </p>
      )}
    </UserBadgeWrapper>
  );
};

export default UserBadge;
