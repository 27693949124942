import React from "react";

import { IProfileData } from "../../pages/about-us";
import getProfile from "../../templates/utils/getProfile";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import {
  ColumnWidthWrapper,
  SmallColumnWidthWrapper
} from "../confines-wrappers";
import Link from "../link";
import UserBadge from "../user-badge";

interface IProps {
  teamMembers: IProfileData[];
}

const TeamMembersWrapper = styled.div`
  h2,
  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .team-members-text {
    h2 {
      margin: 0;
      padding: 0;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};
      letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
      text-transform: uppercase;
    }

    p {
      transform: 0.2s ease-in-out;
      margin-bottom: 0;
      padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_2"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
    }
  }

  .team-members {
    transition: 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3.2rem;

    .team-member {
      transition: 0.2s ease-in-out;
      padding-top: 3.2rem;
      width: 100%;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  .careers-link {
    padding-top: 6.8rem;
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "small_3"
  })};
    font-weight: ${({ theme }) => fontWeightHelper({
    theme,
    variant: "bold"
  })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
  }

  @media (min-width: 480px) {
    .team-members-text {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
      }
    }

    .team-members {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 6.4rem;

      .team-member {
        width: 50%;

        &:nth-child(2) {
          padding-top: 0;
        }
      }
    }

    .careers-link {
      padding-top: 8.8rem;
    }
  }

  @media (min-width: 600px) {
    .team-members-text {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
      }
    }

    .team-members {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 4.8rem;

      .team-member {
        width: 33.33%;

        &:nth-child(3) {
          padding-top: 0;
        }
      }
    }

    .careers-link {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
  }

  @media (min-width: 768px) {
    .team-members {
      padding-top: 6.4rem;

      .team-member {
        padding-top: 3%;
      }
    }
  }

  @media (min-width: 960px) {
    .team-members-text {
      h2 {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
      }

      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
      }
    }

    .team-members {
      padding-top: 6%;

      .team-member {
        width: 25%;

        &:nth-child(4) {
          padding-top: 0;
        }
      }
    }

    .careers-link {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_2"
  })};
    }
  }

  @media (min-width: 1024px) {
    .team-members {
      .team-member {
        width: 20%;

        &:nth-child(5) {
          padding-top: 0;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .team-members {
      padding-top: 8.8rem;
    }
  }
`;

const TeamMembers = ({ teamMembers }: IProps) => (
  <TeamMembersWrapper
    id="meet-the-team"
    className="content-container team-members-wrapper"
  >
    <SmallColumnWidthWrapper className="team-members-text">
      <h2>
        Meet the team
      </h2>

      <p>
        Meet the award-winning team of creative geeks, thinkers and grafters.
        {" "}

        <Link href="/contact">
          Drop us a line
        </Link>

        {" "}
        anytime.
      </p>
    </SmallColumnWidthWrapper>

    <ColumnWidthWrapper className="team-members">
      {teamMembers.map((member: IProfileData, i: number) => {
        const profile = getProfile(member);

        const mugshot =
          profile.relationships && profile.relationships.field_mugshot;

        return (
          <article
            className="team-member"
            key={i}
          >
            <UserBadge
              path={profile.path.alias}
              fullName={profile.field_full_name}
              job={profile.field_job_title}
              summary={profile.field_team_micro_snippet}
              cover={mugshot}
            />
          </article>
        );
      })}
    </ColumnWidthWrapper>

    <SmallColumnWidthWrapper>
      <aside>
        <p className="careers-link">
          <Link
            type="styled"
            href="/careers"
          >
            Current Vacancies
          </Link>
        </p>
      </aside>
    </SmallColumnWidthWrapper>
  </TeamMembersWrapper>
);

export default TeamMembers;
