import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper
} from "../../theme/theme-helper";
import Icon from "../icon";
import Link from "../link";

interface IProps {
  twitterUsername?: string;
  instagramUsername?: string;
}

const AuthorSocialLinksStyled = styled.span`
  display: block;

  a {
    transition: 0.15s ease-in-out;
    display: inline-block;
    margin-right: 2rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border: 0;
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};

    .icon {
      fill: ${({ theme }) => colourHelper({
    theme,
    variant: "lightGrey"
  })};
    }

    &:hover {
      .icon {
        fill: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * Author social links
 */
const AuthorSocialLinks = ({ twitterUsername, instagramUsername }: IProps) => {
  return (
    <AuthorSocialLinksStyled className="socials">
      {twitterUsername && (
        <Link
          href={`https://twitter.com/${twitterUsername}`}
          target="_blank"
        >
          <>
            <span className="off-screen">
              Twitter
            </span>

            <Icon
              className="icon"
              icon="twitter"
              size={2}
            />
          </>
        </Link>
      )}

      <span className="off-screen">
        ,
        {" "}
      </span>

      {instagramUsername && (
        <Link
          href={`https://www.instagram.com/${instagramUsername}`}
          target="_blank"
        >
          <>
            <span className="off-screen">
              Instagram
            </span>

            <Icon
              className="icon"
              icon="instagram"
              size={2}
            />
          </>
        </Link>
      )}
    </AuthorSocialLinksStyled>
  );
};

export default AuthorSocialLinks;
