import * as React from "react";

interface IProps {
  className?: string;
  htmlTag: string;
  children: React.ReactChild;
}

const DynamicHtmlTag = ({
  htmlTag, children, className
}: IProps) =>
  React.createElement(`${htmlTag}`, { className }, children);

export default DynamicHtmlTag;
