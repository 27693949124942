import { IProfileData } from "../../pages/about-us";

const getProfile = (author: IProfileData) => {
  const profile: IProfileData = {
    id: "1",
    relationships: {
      profile__team_profile: [
        {
          changed: "",
          path: { alias: "about-us/pepper" },
          hidden: true,
          field_full_name: "Pepper",
          field_job_title: "Kitchen Patrol",
          relationships: {
            field_mugshot: {
              localFile: {
                childImageSharp: null,
                publicURL: ""
              },
              filename: ""
            }
          }
        }
      ]
    }
  };

  if (author && author.relationships) {
    if (author.relationships.profile__team_profile) {
      return author.relationships.profile__team_profile[ 0 ];
    }
  }

  return profile.relationships.profile__team_profile[ 0 ];
};

export default getProfile;
