import React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { IArticleData } from "../articles-grid/articles-grid";
import DynamicHtmlTag from "../dynamic-html-tag";
import DynamicImage from "../dynamic-image";
import Link from "../link";

interface IProps extends IArticleData {
  htmlHeadingTag?: string;
}

const ArticleWrapper = styled.div`
  * {
    margin-bottom: 0;
  }

  .article-wrapper {
    .article {
      position: relative;
      margin: 0 auto;
      padding-top: 75%;
      max-width: 56rem;

      .heading {
        position: relative;

        .title {
          padding-top: 2.4rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
        }

        span {
          display: block;
        }

        .authored-on {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};
          letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
          text-transform: uppercase;
        }

        .authored-on,
        .authored-by {
          color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "lightGrey"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
        }

        .authored-by {
          padding-top: 1.4rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }

      .image {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;

        a {
          display: block;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 480px) {
    .article-wrapper {
      .article {
        .heading {
          .title {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .article-wrapper {
      .article {
        .heading {
          .title {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          }

          .authored-on {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
          }

          .authored-by {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .article-wrapper {
      .article {
        .heading {
          .title {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }

          .authored-on {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
          }

          .authored-by {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .article-wrapper {
      .article {
        .heading {
          .title {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .article-wrapper {
      .article {
        .heading {
          .title {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .article-wrapper {
      .article {
        .heading {
          .title {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }
`;

const ArticleTeaser = ({
  htmlHeadingTag,
  created,
  path,
  title,
  author,
  cover
}: IProps) => {
  return (
    <ArticleWrapper className="article-container">
      <div className="article-wrapper">
        <article className="article">
          <div className="heading">
            <DynamicHtmlTag
              htmlTag={htmlHeadingTag ? htmlHeadingTag : "h2"}
              className="title"
            >
              <Link
                type="inline"
                color="black"
                underlinecolor="brandColor"
                href={path}
              >
                {title}
              </Link>
            </DynamicHtmlTag>

            {author && (
              <span className="authored-by">
                By
                {" "}

                {author}

                <span className="off-screen">
                  .
                </span>
              </span>
            )}
          </div>

          <div className="image">
            <Link
              type="none"
              href={path}
            >
              <DynamicImage
                data={cover}
                alt={title}
              />
            </Link>
          </div>
        </article>
      </div>
    </ArticleWrapper>
  );
};

export default ArticleTeaser;
